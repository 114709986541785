import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {NotifierService} from 'angular-notifier';
import {ApiService} from '../../../../../services/api.service';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '../../../../../services/config.service';
import {Member} from '../../../../task-manager/models/member';
import {AuthService} from '../../../../../services/auth.service';
import {SearchMatComponent} from '../../search-mat/search-mat.component';
import {NotificationCenterService} from '../../../../notification-center/services/notification-center.service';

@Component({
    selector: 'app-chat-members',
    templateUrl: './chat-members.component.html',
    styleUrls: ['./chat-members.component.scss']
})
export class ChatMembersComponent implements OnInit, OnDestroy {

    // Триггер смерти компонента
    private destroyed = new Subject();
    // isFormDirty
    public changedMembers = false;
    // Main title
    public title = 'Участники чата';
    // Список выббранных людей
    public selected = null;
    public selectedSorted = null;
    // Список потенциальных возможных
    public baseList = null;
    public users = null;
    public usersSorted = null;
    // Юзерыдоступные для выбора
    public availableUsersCurrent = null;
    public availableUsersCurrentSorted = null;
    // Параментры поиска
    public searchParams = '';
    // Развернуть полный список
    public showAll = false;
    // Текущий чат ID
    public chatId = null;
    // EntityType чата
    public type = null;
    // Флаг загрузки
    public isLoaded = false;
    // Компонент поиска
    @ViewChild('search') searchLine: SearchMatComponent;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private notifierService: NotifierService,
        @Inject(MAT_DIALOG_DATA) public data = null,
        private api: ApiService,
        public config: ConfigService,
        private auth: AuthService,
        private notiService: NotificationCenterService
    ) {
    }

    ngOnInit() {
        this.chatId = this.data.id;
        this.type = this.data.type;
        this.selected = this.data.selected.sort((a, b) => !a.removable ? -1 : 1);

        this.getAvailableMembers();
    }

    getAvailableMembers() {
        this.api.getChatAvailableMembers(this.chatId, this.type)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.baseList = [...res];
                    this.users = [...res];
                    this.filterAvailables();

                }
            }, (err) => {
                this.handleError(err);
            });
    }

    searchChanges(param) {
        this.searchParams = param;
        if (this.selected) {
            this.selectedSorted = this.filterValues<Member>(this.searchParams, this.selected);
        }

        if (this.users) {
            this.usersSorted = this.filterValues<Member>(this.searchParams, this.users);
        }
    }

    filterValues<Type>(searchTerm, baseList: Type[]): Type[] {
        let newList;

        if (searchTerm) {
            const terms_str = searchTerm.toLowerCase()
                .split(' ')
                .map(i => i.trim())
                .filter(i => i);
            newList = baseList.filter(
                item => terms_str.every(
                    term => this.testItem<Type>(item, term)
                )
            );
        } else {
            newList = baseList;
        }

        return newList;
    }

    testItem<Type>(item: Type, term: string) {
        return item && (this.testString(item['name'] ? item['name'] : item['first_name'] + item['last_name'], term)) ;
    }

    testString(value: string, term: string) {
        if (!!value) {
            return value.toString().toLowerCase().includes(term);
        }
        return false;
    }

    filterAvailables() {
        this.users = this.baseList.filter(el => !this.selected.find(member => member.id === el.id));

        this.selectedSorted = this.filterValues<Member>(this.searchParams, this.selected);
        // this.selectedSorted.sort((a, b) => !a.removable ? -1 : 1);
        this.usersSorted = this.filterValues<Member>(this.searchParams, this.users);

        setTimeout(() => {
            this.isLoaded = true;
        });
    }

    addUser(item: Member) {
        item['removable'] = true;
        this.selected.push(item);
        this.filterAvailables();
        this.changedMembers = true;
    }

    removeUser(item: Member) {
        if (!item['removable']) {
            return false;
        }
        this.selected = this.selected.filter(el => el.id !== item.id);
        this.changedMembers = true;
        this.filterAvailables();
    }

    isSelf(user) {
        return user.id === this.auth.auth.id;
    }

    closeForm() {
        this.dialogRef.close();
    }

    saveForm() {
        this.dialogRef.close(this.selected);
    }

    ngOnDestroy() {
        this.destroyed.next(true);
        this.destroyed.complete();
    }

    handleError(err) {
        this.notiService.handleFullError(err);
    }

}
