import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ShortTask} from '../models/task';
import {Board} from '../models/board';
import {PlaningBlock} from '../models/planing-block';
import {ViewSettingsGantt, ViewSettingsMenu, ViewSettingsScreen, ViewSettingsTask} from '../models/view-settings';
import {Tag} from '../models/tag';
import {Column} from '../models/column';

@Injectable({
    providedIn: 'root'
})
export class TasksService {
    taskHistory: Task[] = [];

    onBoardsUpdate$ = new BehaviorSubject(null);
    tasks$ = new BehaviorSubject(null);
    ganttAllTasks$ = new BehaviorSubject(null);
    nonHierarchyTasks$ = new BehaviorSubject(null);
    expandedTasks$ = new BehaviorSubject([]);
    taskLoaded$ = new Subject();
    headerCounter$ = new BehaviorSubject(null);
    columns$ = new BehaviorSubject<Column[]>([]);
    planing$ = new BehaviorSubject<PlaningBlock[]>([]);
    settingsTask$ = new BehaviorSubject<ViewSettingsTask>(null);
    settingsGantt$ = new BehaviorSubject<ViewSettingsGantt>(null);
    settingsMenu$ = new BehaviorSubject<ViewSettingsMenu>(null);
    settingsScreen$ = new BehaviorSubject<ViewSettingsScreen>(null);
    /**  перезагрузить задачу штатно */
    reloadTask$ = new Subject();
    /** перезагрузить задачу безусловно */
    forceReloadTask$ = new Subject();
    taskOnSubmit$ = new Subject();
    taskHasChanges$ = new BehaviorSubject(null);
    taskShortHasChanges$ = new BehaviorSubject(null);
    taskCloseConfirmUrl$ = new BehaviorSubject(null);
    taskShortCloseConfirmUrl$ = new BehaviorSubject(null);
    openedTask$ = new BehaviorSubject(null);
    closeTask$ = new Subject();
    movedTaskId$ = new BehaviorSubject(null);
    lastOpenedTask$ = new BehaviorSubject(null);
    lastHoveredTask$ = new BehaviorSubject(null);
    scrollToTask$ = new BehaviorSubject(null);
    onBoardAdd$ = new Subject<Board>();
    onBoardRemove$ = new Subject<Board>();
    searchTerm$ = new BehaviorSubject(null);
    updateChatCnt$ = new BehaviorSubject(null);
    parent$ = new Subject<ShortTask>();
    createFromParent$ = new BehaviorSubject(null);
    createFromChild$ = new BehaviorSubject(null);
    chatIsOpened$ = new BehaviorSubject(null);
    shortView$ = new BehaviorSubject(true);
    resetFilters$ = new Subject();
    taskRouteIsOpen$ = new BehaviorSubject(false);
    onSprintSelect$ = new Subject<Tag>();
    resizeAreaDescription$ = new BehaviorSubject(false);
    changeTask$ = new Subject();
    confirmSubTasks$ = new BehaviorSubject(null);
    saveMessage$ = new Subject();
    //отправка файлов в базу знаний
    addToKB$ = new Subject<{index: number; addKB: boolean; conversion: boolean}>();

    gDocUrl$ = new BehaviorSubject<string>(null);
    gDocUrlForChat$ = new BehaviorSubject<string>(null);
    gDocUrlToReopen$ = new BehaviorSubject(null);
    taskFieldsToSave$ = new BehaviorSubject([]);

    // Id таски редактируемой в планировании
    shortFormEditTaskId$ = new Subject<number>();
    roleClick$ = new Subject();
    saveFilters$ = new Subject();

    finishReviewDialogOpened$ = new BehaviorSubject(null);
    openHistoryRescheduling$ = new Subject();
    taskFieldsView$ = new BehaviorSubject(null);
    lastFieldChanged$ = new Subject();
    recalcAutosize$ = new Subject();
    protocolBoards$ = new BehaviorSubject<Board[]>([]);

    draggingUser$ = new BehaviorSubject(null);
    hiddenView$ = new BehaviorSubject(null);
    restoreTemplateSettings$ = new BehaviorSubject(false);

    updateTemplateStatus$ = new Subject();

    openTaskDialog$ = new Subject();

    updateTask$ = new Subject();

    // храним id задачи которая dragged
    draggedTask$ = new BehaviorSubject(null);

    // Когда нет принимающих для матрицы
    openMatrixApproversDialog$ = new Subject();
    savedDataToAddMatrix$ = new BehaviorSubject(null);
    addTaskToMatrix$ = new Subject();

    // Глушилка при создании пункта чеклиста
    pendingByChecklist$ = new BehaviorSubject(false);

    constructor(
    ) {
    }

    getTasks() {
        return this.tasks$.getValue();
    }

    getColumns() {
        return this.columns$.getValue();
    }

    setTasks(tasks) {
        this.tasks$.next(tasks);
    }

    setColumns(columns) {
        this.columns$.next(columns);
    }

    setPlaning(planing) {
        this.planing$.next(planing);
    }

    addTask(task, position = 'end') {
        const tasks = this.tasks$.getValue();
        if (position === 'end') {
            tasks.push(task);
        } else {
            tasks.unshift(task);
        }
        this.setTasks(tasks);
    }

    getCurrentTask(): any {
        if (this.taskHistory.length > 0) {
            return this.taskHistory[this.taskHistory.length - 1];
        } else  {
            return null;
        }
    }

    getCurrentTaskId() {
        return this.getCurrentTask()?.id || null;
    }

    openTask(task) {
        this.openedTask$.next(task);
        this.taskHistory.push(task);
    }

    closeTask() {
        this.taskHistory.pop();

        if (this.taskHistory.length > 0) {
            this.openedTask$.next(this.getCurrentTask());
        }
    }

}
