import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ApiBoardsService} from '../../../task-manager/services/api-boards.service';
import {TasksService} from '../../../task-manager/services/tasks.service';
import {ConfigBoardsService} from '../../../task-manager/services/config-boards.service';
import {AuthService} from '../../../../services/auth.service';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {NavService} from '../../../task-manager/services/nav.service';
import {BoardsService, isMember} from '../../../task-manager/services/boards.service';
import {Board} from '../../../task-manager/models/board';
import {ConfirmSmartComponent} from '../confirm-smart/confirm-smart.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Filter} from '../../../task-manager/models/filter';
import {FiltersService} from '../../../task-manager/services/filters.service';
import {TaskCreateHelperComponent} from '../../../task-manager/components/task-create-helper/task-create-helper.component';

@Component({
    selector: 'app-short-form-add',
    templateUrl: './short-form-add.component.html',
    styleUrls: ['./short-form-add.component.scss']
})
export class ShortFormAddComponent implements OnInit, OnDestroy {
// Индикатор отображения короткой формы
    public isShortFormOpened = false;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    // Модель формы
    public formGroupShort: UntypedFormGroup = null;
    /** Поле поиска */
    @ViewChild('taskTitle', { static: true }) nameElRef: ElementRef;
    /** Текущая доска */
    public currentBoard: Board = null;
    public isLoaded = false;

    @Input() public parentId = null;
    @Input() public view = null;
    @Input() public paddingLeft = null;
    @Input() public columnId: number = null;
    @Input() public isDashboard: Boolean = false;
    public data = null;

    constructor(
        private notiService: GlobalNotificationCenterService,
        private api: ApiBoardsService,
        private auth: AuthService,
        private tasksService: TasksService,
        private boardsService: BoardsService,
        private nav: NavService,
        private config: ConfigBoardsService,
        public dialog: MatDialog,
        private filtersService: FiltersService
    ) {
    }

    get isMember(){
        return isMember(this.boardsService.boardSelected$.getValue(), this.auth.auth);
    }
    ngOnInit() {
        this.formGroupShort = new UntypedFormGroup({
            title: new UntypedFormControl('', [Validators.required]),
        });

        this.tasksService.taskShortCloseConfirmUrl$
            .pipe(
                takeUntil(this.destroyed),
                debounceTime(500)
            )
            .subscribe(res => {
                if (res && this.formGroupShort.controls['title'].dirty && this.formGroupShort.controls['title'].value) {
                    this.confirmClose();
                }

            }, (err) => {
                this.notiService.handleFullError(err);
            });

        this.formGroupShort.valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res.title && res.title.length) {
                    this.tasksService.taskShortHasChanges$.next(true);
                    this.boardsService.activity$.next(true);
                } else {
                    this.tasksService.taskShortHasChanges$.next(false);
                    this.boardsService.activity$.next(false);
                }

            });

        this.isLoaded = true;
    }

    onKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.onSubmit();
        }
    }

    onSubmit() {
        if (!this.isLoaded || !this.isShortFormOpened) {
            return false;
        }

        const name = this.formGroupShort.controls['title'].value.trim();
        this.formGroupShort.controls['title'].setValue(name);

        if (this.formGroupShort.invalid) {
            this.notiService.handleError(this.config.FORM_INVALID);
            return;
        }

        const active = Filter.prototype.isHelperOn(this.filtersService.tasksFilters$.value);
        if (active) {
            this.openHelperDialog();
        } else {
            this.prepareData();
        }
    }

    prepareData(data = null) {

        const board = this.isDashboard ? this.boardsService.boards$.value.find(b => b.id == (-this.auth.currentUser$.value.id)) : {...this.currentBoard};
        board.tags = [];

        const task = {
            title: this.formGroupShort.controls['title'].value,
            members_observer: board.id > 0 && !board.sharedBoard ? [{user_id: this.auth.currentUser$.value.id}] : [],
            members_performer: board.id < 0 && !board.sharedBoard ? [{user_id: this.auth.currentUser$.value.id}] : [],
            members_approver: [],
            responsible:  board.id < 0 ? {user_id: this.auth.currentUser$.value.id} : null,
            boards: [board],
            parent: this.parentId ? {id : this.parentId} : null,
            assessment_type: board.assessmentType
        };

        if (this.currentBoard.sharedBoard) {
            const baseApprovers = this.boardsService.getBoardBaseApprovers(this.currentBoard).map(el => ({user_id: el.id}));
            const basePerformers = this.boardsService.getBoardBasePerformers(this.currentBoard).map(el => ({user_id: el.id}));
            const baseObservers = this.boardsService.getBoardBaseObservers(this.currentBoard).map(el => ({user_id: el.id}));
            let baseResponsible = this.boardsService.getBoardBaseResponsible(board);
            baseResponsible = baseResponsible ? {user_id: baseResponsible.user_id} : null;
            const baseTags = this.boardsService.getBoardBaseTags(this.currentBoard);

            // Если есть реаном доска то мы принимающий если нет то наблюдатель
            if (board.id > 0 &&
                !baseObservers.find(el => el.user_id === this.auth.currentUser$.value.id) &&
                !basePerformers.find(el => el.user_id === this.auth.currentUser$.value.id) &&
                !baseApprovers.find(el => el.user_id === this.auth.currentUser$.value.id)) {
                if (board.sharedBoard && !this.boardsService.checkMembershipOfBoard(board, this.auth.currentUser$.getValue())) {
                    baseApprovers.push({user_id: this.auth.currentUser$.value.id});
                } else {
                    baseObservers.push({user_id: this.auth.currentUser$.value.id});
                }
            }

            if (board.id < 0 && !basePerformers.find(el => el.user_id === this.auth.currentUser$.value.id)) {
                basePerformers.push({user_id: this.auth.currentUser$.value.id});
            }

            const baseBoard = board;
            baseBoard.tags = baseTags;
            task['boards'] = [baseBoard];

            this.filterUsers(task, baseResponsible, baseApprovers, basePerformers, baseObservers);
        }

        if (data) {
            let performers = data['performers']?.filter(el => el['selected']).map(el => ({user_id: el.id}));
            let observers = data['observers']?.filter(el => el['selected']).map(el => ({user_id: el.id}));
            let responsible = data['responsible'] ? {user_id: data['responsible'].id} : null;
            this.filterUsers(task, responsible, [], performers, observers);

            const group = data['group'] ? data['group'] : null;
            const sprint = data['sprint'] ? data['sprint'] : null;
            const tags = data['tags']?.filter(el => el['selected']);

            const myId = this.auth.auth.id;
            if (!(task['responsible'] && task['responsible'].user_id == myId) &&
                !(task['members_observer'] && task['members_observer'].some(el => el.user_id === myId)) &&
                !(task['members_approver'] && task['members_approver'].some(el => el.user_id === myId)) &&
                !(task['members_performer'] && task['members_performer'].some(el => el.user_id === myId))) {
                task['members_observer'].push({user_id: myId})
            }

            if (task['responsible'] &&
                !task['members_performer'].find(el => el.user_id == task['responsible']['user_id'])) {
                task['members_performer'].push(task['responsible'])
            }

            if (sprint) {
                task['sprint_columns'] = [{column_id: sprint.id, board_id: board.id}];
            }

            if (tags) {
                tags.forEach(tag => {
                    const boardId = tag.boardId;
                    const board = task.boards.find(b => b.id === boardId);
                    board.tags.push(tag);
                });
            }

            if (group) {
                const boardId = group.boardId;
                const board = task.boards.find(b => b.id === boardId);
                board.tags.push(group);
            }

            if (data.importancePriority) {
                const selected = data.importancePriority.find(el => el.selected);
                if (selected) {
                    task['importance'] = selected.id;
                }
            }
        }

        this.data = {task};

        // НА случай ечли задачу надо поместить в конкретную колонку
        if (this.columnId) {
            this.data['destination_column'] = this.columnId;
        }

        this.createTask(this.data);
    }

    filterUsersByOne(task, el) {
        if (!el) {
            return false;
        }
        task['members_approver'] = task['members_approver'].filter(item => item.user_id !== el.user_id);
        task['members_observer'] = task['members_observer'].filter(item => item.user_id !== el.user_id);
        task['members_performer'] = task['members_performer'].filter(item => item.user_id !== el.user_id);
        task['responsible'] = task['responsible'] && task['responsible'].user_id === el.user_id ? null : task['responsible'];
    }

    filterUsers(task, responsible, approvers, performers, observers) {
        approvers.forEach(el => {
            this.filterUsersByOne(task, el)
        });
        task['members_approver'] = [...task['members_approver'] ? task['members_approver'] : [], ...approvers];

        performers.forEach(el => {
            this.filterUsersByOne(task, el)
        });
        task['members_performer'] = [...task['members_performer'] ? task['members_performer'] : [], ...performers];

        observers.forEach(el => {
            this.filterUsersByOne(task, el)
        });
        task['members_observer'] = [...task['members_observer'] ? task['members_observer'] : [], ...observers];

        this.filterUsersByOne(task, responsible);
        task['responsible'] = responsible ? responsible : task['responsible'] ? task['responsible'] : null;
    }

    openHelperDialog() {
        const dialogRef = this.dialog.open(TaskCreateHelperComponent);

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result) {
                    this.prepareData(result);
                } else {
                    this.prepareData();
                }
            });
    }

    createTask(data) {
        const boardId = this.isDashboard ? -this.auth.currentUser$.value.id : this.boardsService.boardSelected$.getValue().id;
        this.boardsService.activity$.next(true);
        this.isLoaded = false;
        this.api.createTask(data, boardId)
            .pipe(takeUntil(this.destroyed))
            .subscribe( res => {
                this.boardsService.activity$.next(false);
                this.tasksService.taskShortHasChanges$.next(null);
                if (this.isDashboard) {
                    this.nav.openTaskInDashById(res.vid);
                    this.parentId ? this.nav.openTaskInDashById(res.vid) : false;
                } else {
                    this.nav.openTaskByVirtualId(res.vid);
                    this.tasksService.scrollToTask$.next(res.id);
                    this.parentId ? this.nav.openTaskByVirtualId(res.vid) : false;
                }
                this.isLoaded = true;
                this.closeShortForm();
            }, err => {
                this.isLoaded = true;
                this.boardsService.activity$.next(false);
                this.notiService.handleFullError(err);
            });
    }

    confirmClose() {
        const dialogData = {
            title: 'Сохранить изменения?'
            ,
            buttons: [
                {
                    color: '_grey',
                    name: 'Не сохранять',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_grey',
                    name: 'Остаться',
                    action: '',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Сохранить',
                    action: 'save',
                    autofocus: true
                }]

        };

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result === 'save') {
                    this.onSubmit();
                } else if (result === 'exit') {
                    this.closeShortForm();
                } else if (!result) {
                    this.tasksService.taskShortCloseConfirmUrl$.next(null);
                }
            });
    }

    openShortForm() {
        if (this.isMember || this.boardsService.boardSelected$.getValue().sharedBoard) {
            this.isShortFormOpened = true;
            this.nameElRef.nativeElement.focus();
            this.currentBoard = this.boardsService.boardSelected$.getValue();
        }
    }

    closeShortForm() {
        this.formGroupShort.controls['title'].setValue('');
        this.tasksService.taskShortHasChanges$.next(null);
        this.isShortFormOpened = false;
    }

    onFocusChange() {
        if (this.isShortFormOpened && !this.formGroupShort.controls['title'].value) {
            this.isShortFormOpened = false;
        }
    }

    placeholder() {
        return this.parentId ? 'Добавить подзадачу' : 'Добавить задачу';
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
